<template>
  <div>
    <el-dialog
      :title="title + '房型'"
      :visible.sync="showing"
      width="550px"
      min-height="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="hide"
    >
      <div v-loading="loading">
        <!-- 一个表单 -->
        <el-form
          ref="formInline"
          :model="formInline"
          @submit.native.prevent
          :rules="rules"
        >
          <el-form-item label="酒店" prop="hotelId">
            <el-select
              class="text"
              style="width: 100%"
              v-model="formInline.hotelId"
              :disabled="hotel"
              placeholder="请选择酒店"
            >
              <el-option
                v-for="item in hotelList"
                :key="item.id"
                :label="item.hotelName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div class="banben">
            <el-form-item label="房型名称" prop="typeName" style="width: 49%">
              <el-input
                placeholder="请输入房型名称"
                v-model="formInline.typeName"
                style="width: 100%"
                maxlength="10"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="排序"
              prop="dispOrder"
              style="width: 49%"
              v-if="title == '编辑'"
            >
              <el-input
                placeholder="请输入排序"
                v-model="formInline.dispOrder"
                style="width: 100%"
                maxlength="10"
              ></el-input>
            </el-form-item>
          </div>
          <div class="banben">
            <el-form-item
              label="固件版本"
              prop="currentVersion"
              style="width: 49%"
              v-if="title == '编辑'"
            >
              <el-select
                class="text"
                v-model="formInline.currentVersion"
                placeholder="请选择固件版本"
              >
                <el-option
                  v-for="item in versionList"
                  :key="item.id"
                  :label="item.versionNo"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="缩略图" prop="picture" style="width: 49%">
              <div @click="upLoadFile" class="">
                <input
                  type="file"
                  ref="fileInput"
                  @change="readFile"
                  style="display: none"
                />
                <img
                  class="img"
                  :src="formInline.picture"
                  alt
                  v-if="formInline.picture"
                />
                <div class="add" v-else>
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </div>
              </div>
            </el-form-item> -->
          </div>

          <div class="sure">
            <el-button
              size="small"
              type="primary"
              @click="createStorage('formInline')"
              >确定</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import { createInsertUser, updateInsertUser } from "@/api/api2.js";
import { getRequest, postRequest } from "@/api/api.js";

export default {
  data() {
    return {
      //审核弹窗
      title: "",
      showing: false,
      loading: false,
      bedisabled: false,
      hotel: true,
      index: null,
      hotelList: [],
      versionList: [],
      id: null,
      //表单
      formInline: {},
      //表单验证
      rules: {
        hotelId: [
          {
            required: true,
            message: "请选择酒店",
            trigger: "blur",
          },
        ],
        typeName: [
          {
            required: true,
            message: "请输入房型名称",
            trigger: "blur",
          },
        ],
        dispOrder: [
          {
            required: true,
            message: "请输入排序",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["state"],
  methods: {
    hide() {
      this.fileListUpload = [];
      this.showing = false;
      this.$refs.formInline.resetFields(); //清空表单
      this.formInline.statusRemark = "";
      this.formInline.versionCode = "";
    },
    show(item, val, index) {
      this.formInline = {};
      let hotelInfor = JSON.parse(window.localStorage.getItem("hotelInfor"));
      this.formInline.hotelId = hotelInfor.hotelId;
      this.getHotelList();
      this.title = item;
      this.showing = true;
      this.bedisabled = false;
      if (item == "编辑") {
        this.bedisabled = true;
        val.status = Number(val.status);
        val.usertype = Number(val.usertype);
        val.isadmin = Number(val.isadmin);
        this.formInline = JSON.parse(JSON.stringify(val));
        this.getVersionList();
        this.index = index;
      }
    },
    getHotelList() {
      let parms = {
        offset: 1,
        num: 10000,
      };
      postRequest("selectAllHotel", parms).then((res) => {
        if (res.status == 200) {
          this.hotelList = res.data.list;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    getVersionList() {
      getRequest("selectAllVersions?roomTypeId=" + this.formInline.id).then(
        (res) => {
          if (res.status == 200) {
            this.versionList = res.data;
          } else {
            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
            });
          }
        }
      );
    },
    upLoadFile() {
      this.$refs.fileInput.click();
    },
    readFile(e) {
      let that = this;
      let file = e.target.files[0];
      let name = "." + file.name.split(".")[1];
      if (!name.match(/.png|.gif|.jpeg|.jpg|.jfif/i)) {
        this.$message({
          duration: 5000,
          message: "请选择图片格式!",
          type: "error",
          time: 2000,
          hasClose: true,
        });
        return;
      }
      console.log(2323, file.size / 1024 / 1024);
      if (file.size / 1024 / 1024 >= 0.05) {
        this.$message({
          duration: 5000,
          message: "请选择合适大小图片!",
          type: "error",
          time: 2000,
          hasClose: true,
        });
        this.formInline.picture = null;
        return;
      }
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        that.$set(that.formInline, "picture", this.result);
      };
      console.log(111, that.formInline);
    },
    createStorage(formData) {
      // 表单验证-----------

      // 新增
      this.$refs[formData].validate((valid) => {
        if (valid) {
          if (this.title == "创建") {
            console.log(this.formInline);
            postRequest("/insertHotelType", this.formInline).then((res) => {
              if (res.status == 200) {
                this.$message({
                  duration: 5000,
                  message: "创建成功!",
                  type: "success",
                });
                this.hide();
                this.$refs.formInline.resetFields(); //清空表单
                this.$parent.getFounderList();
              } else {
                this.$message({
                  duration: 5000,
                  message: res.message,
                  type: "warning",
                });
              }
            });
          } else {
            this.updateStation(); //调用编辑函数
          }
        } else {
          this.$message({
            duration: 5000,
            message: "请仔细核对填写的内容！",
            type: "warning",
          });
        }
      });
    },
    // 编辑
    updateStation() {
      postRequest("/updateHotelType", this.formInline).then((res) => {
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "编辑成功!",
            type: "success",
          });
          this.hide();
          this.$refs.formInline.resetFields(); //清空表单
          this.$parent.getFounderList();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "warning",
          });
        }
      });
    },
    //附件
    //上传附件----------------------------------------------
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      let isFlag = true;
      if (!isLt5M) {
        this.$message.error("上传头像文件大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //--------------------------------------------------------------------
    // 成功之后的返回
    handleAvatarSuccess(res, file) {
      this.fileJson.push(res.data);
      this.fileListUpload.push(res.data);
    },

    // 删除之后的返回值
    handleRemove(file, fileList) {
      this.fileJson.forEach((fileId, index) => {
        if (fileId.id == file.id) {
          this.fileJson.splice(index, 1);
          this.fileListUpload.splice(index, 1);
        }
      });
    },
    //预览
    handlePreview(file) {
      let fileName = file.fileName;
      //获取最后一个.的位置
      let index = fileName.lastIndexOf(".");
      //获取后缀
      let ext = fileName.substr(index + 1);
      if (this.isAssetTypeAnImage(ext)) {
        //图片预览
        window.open(
          window.string.BUSINESS_PUBLIC_API_URL +
            "/sys/attachments/getImageByStream/?id=" +
            file.id
        );
      } else {
        //永中预览
        previewUpload(file.id).then(({ data, result, message }) => {
          if (result == 1) {
            var previewUrl = data.filePath;
            window.open(window.string.PUBLIC_VIEW_FILE_API + previewUrl);
          } else {
            this.$message({
              duration: 5000,
              message: message,
              type: "warning",
            });
          }
        });
      }
    },
    isAssetTypeAnImage(ext) {
      return (
        ["png", "jpg", "jpeg", "bmp", "gif"].indexOf(ext.toLowerCase()) !== -1
      );
    },
    handleDowload(file) {
      window.location.href =
        window.string.BUSINESS_PUBLIC_API_URL +
        "/sys/attachments/donwload/?id=" +
        file.id;
    },
  },
};
</script>

<style scoped>
.el-form-item__label {
  display: block !important;
  width: 100% !important;
}
.el-radio-group {
  width: 100%;
}
.el-button {
  width: 320px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.sure {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d8d8d8;
}
.banben {
  display: flex;
  justify-content: space-between;
}
.el-button {
  width: 200px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.el-button:hover {
  transform: scale(1.1);
}
.upLoadFile {
  width: 100px;
  height: 100px;
  line-height: 150px;
  /* background: #1471fe; */
}
.img {
  width: 100px;
  height: 100px;
}
.add {
  border: 1px dashed #cdd1d6;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
}
.upImg {
  /* background-color: aquamarine; */
  height: 150px;
  display: flex;
  align-items: center;
}
</style>